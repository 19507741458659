window.angular.module('MyHippoProducer').config(function ($stateProvider, $urlRouterProvider) {
    // Default Route
    $urlRouterProvider.otherwise('/home');

    $stateProvider.state('home', {
        url: '/home?:accessToken&:producerId',
        params: {
            accessToken: {dynamic: true},
            producerId: {dynamic: true}
        },
        templateUrl: 'views/home.html',
        controller: 'HomeController',

        resolve: {
            // redirect homepage to React App producer url
            redirect: function ($window, $q, $location) {
                const queryParams = $location.search();
                if (!queryParams.accessToken && queryParams.producerId !== 'superUser') {
                    const reactProducerDomain = window.REACT_PRODUCER_DOMAIN;
                    const protocol = $location.protocol();
                    // handle angular url redirection 
                    // ex: https://danielmainorg.producer.staging.myhippo.io/#/leads/65f9361e-ce03-4734-a648-d55a7fdd04f3
                    const hash = window.location.hash;
                    $window.location.href = hash && !hash.includes('home') ? 
                        `${protocol}://${reactProducerDomain}/login/${hash}` : `${protocol}://${reactProducerDomain}/login`;
                    return $q.reject({error: 'Invalid login url'});
                } else if ($window.location.host.indexOf(`.${$window.PRODUCER_SUBDOMAIN}.`) >= 0) { // this is producer sub-domain, need to redirect
                    const subdomain = $window.location.host.split('.')[0]; // get organization sub-domain
                    $window.location.href = $window.location.href.replace(`://${subdomain}.`, '://'); // redirect to base producer url
                    return $q.reject({ error: 'Invalid login url' });
                }
            }
        }
    });

    $stateProvider.state('forgotPassword', {
        url: '/forgot-password',
        templateUrl: 'views/forgot-password.html',
        controller: 'ForgotPasswordController',
        resolve: {
            redirect: function ($window, $q, $location) {
                const reactProducerDomain = window.REACT_PRODUCER_DOMAIN;
                const protocol = $location.protocol();

                $window.location.href = `${protocol}://${reactProducerDomain}/forget-password`;
                return $q.reject({error: 'Invalid login url'});
            }
        }
    });

    $stateProvider.state({
        name: 'portal',
        abstract: true,
        data: {requiresLogin: true},
        templateUrl: 'views/portal.html',
        controller: 'PortalController',
        resolve: { user: APIService => APIService.findCurrentUser() }
    });

    $stateProvider.state('portal.dashboard', {
        url: '/dashboard',
        templateUrl: 'views/dashboard.html',
        controller: 'DashboardController'
    });

    $stateProvider.state('portal.policiesList', {
        url: '/policies?:scope&:organization&:status&:effectiveDateAfter&:effectiveDateBefore&:timeRange&:keyword',
        params: {
            scope: {dynamic: true},
            organization: {dynamic: true},
            status: {dynamic: true},
            effectiveDateAfter: {dynamic: true},
            effectiveDateBefore: {dynamic: true},
            timeRange: {dynamic: true},
            keyword: {dynamic: true},
        },
        templateUrl: 'views/policies/policy.list.html',
        controller: 'PolicyListController'
    });

    $stateProvider.state('portal.policyCreate', {
        url: '/policies/create',
        templateUrl: 'views/policies/policy.create.html',
        controller: 'PolicyCreateController',
        controllerAs: 'createCtrl',
        resolve: {
            clientAppUrl: function ($mdDialog, $stateParams, APIService, $state) {
                return APIService.findAvailableOrganizationsForUser().then((organizations) => {
                    const availableOrganizationsToCreateFrom = organizations.filter(o => o.is_producer && o.disable_policy_creation !== true);
                    if (availableOrganizationsToCreateFrom.length > 1) { // Producers have at least 1 org (main org)
                        return $mdDialog.show({
                            template: `<mh-select-policy-creation-modal choices="orgChoices"></mh-select-policy-creation-modal>`,
                            controller: function ($scope) { $scope.orgChoices = availableOrganizationsToCreateFrom; }
                        }).then(({ id }) => {
                            if (id) {
                                return APIService.findLeadUrl('new', '', id);
                            } else {
                                const returnPath = $state.current.name || 'portal.policiesList';
                                $state.go(returnPath);
                            }
                        });
                    } else {
                        return APIService.findLeadUrl('new', '');
                    }
                });
            }
        }
    });

    $stateProvider.state('portal.policyDetails', {
        url: '/policies/:id/:pageKey?',
        params: {
            pageKey: 'policy_information_page'
        },
        templateUrl: 'views/policies/policy.details.html',
        controller: 'PolicyDetailsController',
        resolve: {
            'policyData': function ($stateParams, APIService) {
                return APIService.findPolicyById($stateParams.id, $stateParams.updateId);
            }
        }
    });
    // V2
    $urlRouterProvider.when('/policies2/:id/', '/policies2/:id/view/policy_information');
    $urlRouterProvider.when('/policies2/:id/view', '/policies2/:id/view/policy_information');
    $stateProvider.state('portal.policyDetailsV2', {
        url: '/policies2/:id/:mode/:page',
        templateUrl: 'pages/policy-details/policy-details.html',
        controller: 'PolicyDetailsV2Controller',
        params: {
            page: 'policy_information',
            mode: 'view',
        },
        resolve: {
            'policyData': function ($stateParams, PolicyService, $log) {
                const { id, mode } = $stateParams;

                const currentPolicyId = PolicyService.id;
                const currentMode = PolicyService.isEditMode ? 'edit' : 'view';
                if (currentPolicyId != id || currentMode !== mode) {
                    $log.info(`Fetching policy ${id}`);
                    return PolicyService.fetch(id, mode === 'edit', true);
                }
            }
        }
    });

    $stateProvider.state('portal.licenseError', {
        url: '/policies2/:id/license-errors',
        templateUrl: 'pages/errors/license-error/license-error.html',
        params: {
            error: '',
        },
    });

    $stateProvider.state('portal.pendingPolicies', {
        url: '/pending?:sortField&:sortOrder',
        params: {
            sortField: {dynamic: true},
            sortOrder: {dynamic: true}
        },
        templateUrl: 'views/policies/policy.pending.html',
        controller: 'PendingPoliciesController'
    });

    $stateProvider.state('portal.leadsList', {
        url: '/leads?:scope&:organization&:timeRange&:keyword',
        params: {
            scope: {dynamic: true},
            organization: {dynamic: true},
            timeRange: {dynamic: true},
            keyword: {dynamic: true},
        },
        templateUrl: 'views/leads/lead.list.html',
        controller: 'LeadListController'
    });

    $stateProvider.state('portal.leadDetails', {
        url: '/leads/:id?pageId',
        params: {pageId: ''},
        templateUrl: 'views/leads/lead.details.html',
        controller: 'LeadDetailsController',
        resolve: {
            'leadUrl': function ($stateParams, APIService) {
                return APIService.findLeadUrl($stateParams.id, $stateParams.pageId);
            }
        }
    });

    $stateProvider.state('portal.producersList', {
        url: '/producers',
        templateUrl: 'views/producers/producer.list.html',
        controller: 'ProducerListController',
        data: {requiresRoles: ['admin', 'principal']}
    });

    $stateProvider.state('portal.producerDetails', {
        url: '/producers/:id',
        params: {
            id: 'create',
            pageKey: {
                squash: true, value: 'producer_details_page'
            }
        },
        templateUrl: 'views/producers/producer.details.html',
        controller: 'ProducerDetailsController',
        controllerAs: 'producerCtrl',
        data: {requiresRoles: ['admin', 'principal']},
        resolve: {
            producerData: function ($stateParams, APIService) {
                return APIService.findProducerById($stateParams.id);
            }
        }
    });

    $stateProvider.state('portal.organizationsList', {
        url: '/organizations',
        templateUrl: 'views/organizations/organization.list.html',
        controller: 'OrganizationListController',
        data: {requiresRoles: ['principal']}
    });

    $stateProvider.state('portal.organizationDetails', {
        url: '/organization/:id',
        params: {
            pageKey: {
                squash: true, value: 'organization_details_page'
            }
        },
        templateUrl: 'views/organizations/organization.details.html',
        controller: 'OrganizationDetailsController',
        controllerAs: 'organizationCtrl',
        data: {requiresRoles: ['principal']},
        resolve: {
            organizationData: function ($stateParams, APIService) {
                return APIService.findOrganization($stateParams.id);
            }
        }
    });

    $stateProvider.state('portal.notificationsList', {
        url: '/notifications',
        templateUrl: 'views/notifications/notifications.list.html',
        controller: 'NotificationListController'
    });

    $stateProvider.state('portal.reports', {
        url: '/reports',
        templateUrl: 'views/reports/reports.html',
        controller: 'ReportsController'
    });
});
